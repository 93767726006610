<div class="jumbotron" role="main">


    <!-- Teams -->
  
    <form [formGroup]="profileForm">
      <h2 text-align="center">Link do Teams para reunião</h2>
      <div class="container">
        <hr>  
        <label for="subject"><b>*Título: </b></label>
        <input class="form-control" formControlName="subject" type="text" placeholder="Adicione um Título!" name="subject" id="subject" required><br><br>
  
        <label  for="dateTime"><b>Data:  </b></label>
        <input class="form-control" formControlName="date" type="date"  placeholder="dd/mm/aaaa" id="data" name="data">
  
        <label for="hora-comeco"><b> Começa:  </b></label>
        <input class="form-control" formControlName="start" type="time"  id="hora-comeco" name="hora-comeco">
  
        <label for="hora-fim"><b> Até:  </b></label>
        <input class="form-control" formControlName="end" type="time"  id="hora-fim" name="hora-fim"><br><br>
  
        <button class="btn btn-primary btn-large" (click)="AddEvent(); SendLikeEvent()" type="button" id="gerar">Gerar Link</button>
         <button class="btn btn-primary btn-large" (click)="sendEmail()" type="button" id="gerar">Enviar email</button>
  
      </div>
    </form>
  </div>