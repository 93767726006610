
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {  createCalendar } from './calendar';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth.service';


@Injectable({providedIn:'root'})
export class CalendarService {

  baseURL: string = "https://graph.microsoft.com/beta/me";

  constructor( 
    private http: HttpClient,
    private authService: AuthService) {
 }


  async addCalendar(objeto:createCalendar): Promise<any> {
    let token = await this.authService.getAccessToken()
    const headers = { 
      'Authorization': 'Bearer '+ token, 
      'Content-Type': 'application/json'
    }
    const body=JSON.stringify(objeto);
    return this.http.post(`${this.baseURL}/onlineMeetings`, body, {'headers':headers}).toPromise();
    
  }

}